.imageUpload {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 25px;
}

.progress {
  width: 100%;
}

.uploadCapBtn {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.uploadCap {
  width: 70%;
}

.uploadBtn {
  width: 30%;
}
