* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}
body {
  color: #262626;
  font-size: 14px;
  line-height: 18px;
}
.app {
  background-color: #fff6f7;
  min-height: 100vh;
}
.app__header {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: white;
  height: 75px;
  display: flex;
  align-items: center;
  object-fit: contain;
  border-bottom: 1px solid lightgray;
}

.logo {
    width: 15%; /* adjust this value as needed */
    height: auto;
    margin-top: 2px;
}


.profile.button {
    width: 139px;
    height: 39px;
    border-radius: 20px;
    margin-left: 50px;
    margin-top: 20px;
    background: transparent;
    transition: background 0.15s;

    position: relative;
    overflow: hidden;
}

.profile-text {
    position: absolute;
    opacity: 0; /* Hide text initially */
    transition: opacity 0.15s;
    left: 50%;  /* Center the text */
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap; /* Ensures text stays on one line */
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
    color: white;
}

.profile.button:hover .profile-text {
    opacity: 1; /* Show text on hover */
}

.profile.button:hover {
    background: rgb(255, 136, 156);
}

.profile.icon {
    width: 45px;
    height: auto;
    top: 50%;  
    left: 50%; 
    transform: translate(-111%, -7.5%);
}


.app__headerWrapper {
  max-width: 975px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app__headerButtons {
  display: flex;
  column-gap: 15px;
}
button {
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  border: 1px solid transparent;
}
.primary__button {
  background-color: #0095f6;
  border-radius: 4px;
  color: #fff;
  padding: 5px 9px;
  font-weight: 600;
  text-align: center;
}
.text__button {
  color: #0095f6;
  font-weight: 600;
  line-height: 28px;
  background-color: transparent;
}
.app__signUp {
  display: flex;
  flex-direction: column;
  margin: 28px 0;
  row-gap: 15px;
}

.Signerup {
    width: 90%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.timeline {
  margin: 0 auto;
  max-width: 500px;
  padding: 20px;
}

button {
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  border: 1px solid transparent;
}

.primary__button {
  background-color: #0095f6;
  border-radius: 4px;
  color: #fff;
  padding: 5px 9px;
  font-weight: 600;
  text-align: center;
}

.text__button {
  color: #0095f6;
  font-weight: 600;
  line-height: 28px;
  background-color: transparent;
}

.app__signUp {
  display: flex;
  flex-direction: column;
  margin: 28px 0;
  row-gap: 15px;
}

input {
  padding: 5px 9px;
  line-height: 18px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 3px;
}

input:focus {
  border: 1px solid gainsboro;
}

.authFooter {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.authFooter small {
  color: rgb(172, 172, 172);
}

.authFooter hr {
  border: 0;
  border-top: 1px solid;
  border-color: lightgray;
}

.authFooter a {
  color: #0095f6;
}