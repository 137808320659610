.post {
  background-color: white;
  border: 1px solid lightgray;
  margin-bottom: 45px;
}
.post__image {
  width: 100%;
  object-fit: contain;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.post__text {
  font-weight: normal;
  padding: 15px 20px;
}
.post__header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.post__headerLeft {
    /* Keeps the profile picture and name grouped and aligned to the left */
    display: flex;
    align-items: center;
  }

.post__avatar {
  margin-right: 10px;
}

.post__comments {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.comment__form {
  margin: 0px 20px 20px 20px;
}

.comment__wrapper {
  display: flex;
  width: inherit;
  background-color: #fafafa;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding: 8px;
}

.comment__wrapper input {
  width: 70%;
  border: none;
  background: transparent;
}

.comment__wrapper input:focus {
  border: none;
}

.comment__wrapper button {
  width: 30%;
  text-align: center;
}

.deleteCommentButton {
    border: none;
    cursor: pointer;
    padding: 0;
    border-radius: 13px;
    transition: background-color 0.15s;
    background: transparent;
    height: 25px;
    width: 25px;
  }

  .deleteCommentButton:hover {
    background: rgb(255, 146, 164);
  }
  
  .deleteCommentButton img {
    width: 15px; /* Adjust size as needed */
    height: auto;
    margin-top: 3px;
  }
  
  .deletePostButton {
    height: 25px;
    width: 25px;
    border: none;
    cursor: pointer;
    padding: 0;
    border-radius: 13px;
    transition: background-color 0.15s;
    background: transparent;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 12px;
  }

  .deletePostButton:hover {
    background: rgb(255, 146, 164);
  }

  .deletePostButton img {
    width: 15px; /* Adjust size as needed */
    height: auto;
    margin-top: 4px;
  }